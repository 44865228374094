import React from "react"
import style from "../pages/recommendations.module.css"

function LinkList({children}) {
    return(
        <ul>
            {children}
        </ul>
    )
}

function RecommendationList({children}) {
    return(
    <div class={style.row}>
      {children}
    </div>
  )
}
  
export {RecommendationList, LinkList}