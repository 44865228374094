import React from "react"
import { css } from "@emotion/core"

export default function DividedBlock({ children }) {
    return (
        <div css={css`
          border-bottom: 2px solid #111111;
          padding-bottom: 30px;
          margin-bottom: 30px;
        `}>
          {children}
        </div>
  )
}