import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Img from "gatsby-image"
import style from "./recommendations.module.css"
import PageTitle from "../components/page-title"
import { Heading } from "../components/heading"
import DividedBlock from "../components/divided-block"
import {RecommendationList, LinkList} from "../components/recommendation-list"
import SEO from "../components/seo"

const Recommendation = props => (
  <div class={style.column}>
    <div>
      <Img fixed={props.img} />
    </div>
  </div>
)

const ArticleRec = props => (
  <li>
    <a class={style.red_link} href={props.link}>
      {props.title}
    </a>
  </li>
)

export default function Recommend({ data }) {
  return (
    <Layout>
      <SEO
        title="Recommendations"
        description="Some of Connor's favorites"
      />
      <PageTitle>Recommendations</PageTitle>
      <Heading>Books</Heading>

      <DividedBlock>
        <RecommendationList>
          <Recommendation 
            img={data.book_1q84.childImageSharp.fixed}
          />
          <Recommendation 
            img={data.impossible.childImageSharp.fixed}
          />
          <Recommendation 
            img={data.sapiens.childImageSharp.fixed}
          />
          <Recommendation 
            img={data.death.childImageSharp.fixed}
          />
          <Recommendation 
            img={data.kondo.childImageSharp.fixed}
          />
          <Recommendation 
            img={data.sour.childImageSharp.fixed}
          />
          <Recommendation 
            img={data.usagi.childImageSharp.fixed}
          />
          <Recommendation 
            img={data.watchmen.childImageSharp.fixed}
          />
          <Recommendation 
            img={data.sculpt.childImageSharp.fixed}
          />
          <Recommendation 
            img={data.catch.childImageSharp.fixed}
          />
        </RecommendationList>
      </DividedBlock>

      <DividedBlock>
      <Heading>Movies</Heading>
      <RecommendationList>
        <Recommendation 
            img={data.mishima.childImageSharp.fixed}
          />
          <Recommendation 
            img={data.mononoke.childImageSharp.fixed}
          />
          <Recommendation 
            img={data.lost.childImageSharp.fixed}
          />
          <Recommendation 
            img={data.rock.childImageSharp.fixed}
          />
          <Recommendation 
            img={data.general.childImageSharp.fixed}
          />
          <Recommendation 
            img={data.apocalypse.childImageSharp.fixed}
          />
        </RecommendationList>
      </DividedBlock>

      <Heading>Online Writing</Heading>
      <LinkList>
        <ArticleRec 
          link="https://charleseisenstein.org/essays/building-a-peace-narrative/"
          title="Building a Peace Narrative"
        />
        <ArticleRec 
          link="https://www.vulture.com/2020/06/anti-racist-reading-lists-what-are-they-for.html"
          title="What Is an Anti-Racist Reading List For?"
        />
        <ArticleRec 
          link="https://aaronzlewis.com/blog/2019/05/29/you-can-handle-the-post-truth/"
          title="You Can Handle the Post-Truth: A Pocket Guidie to the Surreal Internet"
        />
        <ArticleRec 
          link="https://www.wired.com/2017/01/how-i-got-my-attention-back/"
          title="How I Got My Attention Back"
        />
        <ArticleRec 
          link="https://www.wired.com/story/mirrorworld-ar-next-big-tech-platform/"
          title="AR Will Spark the Next Big Tech Platform -- Call it Mirrorworld"
        />
        <ArticleRec 
          link="https://www.ribbonfarm.com/2020/09/03/wittgensteins-revenge/"
          title="Wittgenstein's Revenge"
        /> 
      </LinkList>
    </Layout>
  )
}

export const squareImage = graphql`
  fragment squareImage on File {
    childImageSharp {
      fixed(width: 162, height: 250) {
        ...GatsbyImageSharpFixed
      }
    }
  }
`

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    book_1q84: file(relativePath: { eq: "assets/1q84.jpg" }) {
      ...squareImage
    }

    impossible: file(relativePath: { eq: "assets/how to have impossible.jpg" }) {
      ...squareImage
    }

    sapiens: file(relativePath: { eq: "assets/sapiens.jpg" }) {
      ...squareImage
    }

    fall: file(relativePath: { eq: "assets/fall.jpg" }) {
      ...squareImage
    }

    death: file(relativePath: { eq: "assets/deathend.jpg" }) {
      ...squareImage
    }

    kondo: file(relativePath: { eq: "assets/kondo.jpg" }) {
      ...squareImage
    }

    catch: file(relativePath: { eq: "assets/catch22.jpg" }) {
      ...squareImage
    }

    saltfat: file(relativePath: { eq: "assets/saltfat.jpg" }) {
      ...squareImage
    }

    sculpt: file(relativePath: { eq: "assets/sculptor.jpg" }) {
      ...squareImage
    }

    sour: file(relativePath: { eq: "assets/sourdough.jpg" }) {
      ...squareImage
    }

    usagi: file(relativePath: { eq: "assets/usagi.jpg" }) {
      ...squareImage
    }

    watchmen: file(relativePath: { eq: "assets/watchmen.jpg" }) {
      ...squareImage
    }

    mishima: file(relativePath: { eq: "assets/mishima.jpg" }) {
      ...squareImage
    }

    mononoke: file(relativePath: { eq: "assets/mononoke.jpg" }) {
      ...squareImage
    }

    lost: file(relativePath: { eq: "assets/lost.jpg" }) {
      ...squareImage
    }

    rock: file(relativePath: { eq: "assets/rock.jpg" }) {
      ...squareImage
    }

    general: file(relativePath: { eq: "assets/general.jpg" }) {
      ...squareImage
    }

    apocalypse: file(relativePath: { eq: "assets/apocalypse.jpg" }) {
      ...squareImage
    }
  }
`
